canvas{
       /* border: 1px solid black; */
    top:-1%;
    position:fixed;
    left:-1%;
    width:102%;
    height:102%;

}


@font-face {
    font-family: 'INFO56_0';
    src: url('../../fonts/INFO56_0.ttf');
    font-weight: 400;
    font-weight: normal;
}




@font-face {
    font-family: 'aonchlo';
    src: url('../../fonts/aonchlo.ttf');
    font-weight: 400;
    font-weight: normal;
}
@font-face {
    font-family: 'anaphora';
    src: url('../../fonts/Anaphora-Light-trial.ttf');
    font-weight: 400;
    font-weight: normal;
}
.wait-and-fade{
    animation: wait-and-fade 5s steps(8) forwards;    
}

@keyframes wait-and-fade {
    0% {
        opacity: 1;
    }
    1%{opacity: 1;}
    70%{opacity: 1;}
    100% {
        opacity: 0;
    }
}
.molly{
    left: 5%;
    transform: scale(3) scaleX(-1);
    top: 77%;
    position: fixed;
  }

  .fullscreen-image {
    position: fixed;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
    display: flex;
    justify-content: center; /* Horizontally center the content */
    align-items: center; /* Vertically center the content */
    z-index: 9999; /* Ensure it's on top of other elements */

    max-width: 100%;
    max-height: 100%;
    object-fit: cover; /* Maintain aspect ratio and fit within the container */
}
.bally-rings{
    /* top: 25%; */
}


  
  .fullscreen-image {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
    z-index: 9999; /* Ensure it's on top of other elements */
    max-width: 100%;
    max-height: 100%;
    object-fit: cover; /* Maintain aspect ratio */
  }
  
  /* Optional: Adjust for mobile browser address bar, add extra padding if needed */
  @media (max-width: 768px) { /* Adjust for smaller screens */
    .touch-prompt-container {
      top: calc(50vh +40px ); /* Offset slightly to account for browser bar */
    }
  }
  


.player-message {
  position: absolute;
  top: 15%; /* Position in the top half of the screen */
  left: 50%;
  transform: translateX(-50%); /* Center horizontally */
  max-width: 60%; /* Optional: control the width of the bubble */
  padding: 20px 30px;
  background-color: #f0f0f0; /* Light background for speech bubble */
  border-radius: 20px; /* Rounded corners for bubble shape */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Optional shadow */
  font-size: 2.5em;
  font-family: aonchlo;
  color: #333; /* Text color */
  text-align: center;
  border: 2px solid #ccc; /* Optional border */
  opacity: 0; /* Hidden by default */
  transition: opacity 0.5s ease-in-out; /* Smooth fading */
  visibility: hidden; /* Hide the bubble initially */
}

.player-message.visible {
  visibility: visible; /* Make it visible when needed */
}

.tail {
  position: absolute; /* Position relative to the message bubble */
  width: 0; /* No width, as it's a triangle */
  height: 0; /* No height, as it's a triangle */
  border-left: 10px solid transparent; /* Creates the left side of the triangle */
  border-right: 10px solid transparent; /* Creates the right side of the triangle */
  border-top: 10px solid #f0f0f0; /* Creates the top side of the triangle, matching the bubble color */
  top: 100%; /* Positions the tail directly above the message bubble */
  left: 50%; /* Center the tail horizontally */
  transform: translateX(-50%); /* Adjust to center the tail */
  opacity: 0; /* Start hidden */
  transition: opacity 0.5s ease; /* Smooth transition for fading */
  z-index: 0; /* Ensure the tail is behind the message bubble */
}
