@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.spinner {
    border: 8px solid rgba(255, 255, 255, 0.2); /* Light border */
    border-top: 8px solid #ffffff; /* Top border color */
    border-radius: 50%;
    width: 40px; /* Size of the spinner */
    height: 40px;
    animation: spin 1s linear infinite; /* Spin animation */
    margin: 0 auto; /* Center the spinner */
}
