/* easca.css - Ink Sketch Style */
@font-face {
    font-family: 'aonchlo';
    src: url('../../fonts/aonchlo.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
  
/* Base Keyboard Container */
body .hg-theme-default {background: transparent !important;
width: 72% !important;
left: 0 !important;
padding: 1.5% !important;
position: fixed;
top: 16%;
}
  
  /* Individual Keys */
  body .hg-theme-default .hg-button {
	font-family: 'aonchlo', sans-serif !important;
	background: rgba(21, 19, 17, 0.15) !important; /* Faint ink wash */
	border-radius: 12px !important; /* Rounded edges */
	color: rgba(60, 45, 30, 0.85) !important; /* Dark ink color */
	font-size: 2rem !important;
	text-align: center !important;
	text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
	box-shadow: 
	  0 2px 4px rgba(0, 0, 0, 0.05),
	  inset 0 -1px 1px rgba(255, 255, 255, 0.2);
	transition: all 0.15s ease-out;
	margin: 0.25rem !important;
	min-width: 3.5rem !important;
	height: 4rem !important;
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;
  }
  
  /* Key Press Effect */
  body .hg-button:active {
	background: rgba(123, 90, 70, 0.25) !important;
	transform: translateY(1px) scale(0.98) !important;
	box-shadow: 
	  0 1px 2px rgba(0, 0, 0, 0.05),
	  inset 0 1px 1px rgba(255, 255, 255, 0.2) !important;
  }
  
  /* Special Characters */
  body .hg-button[data-skbtn^="{"] {
	font-size: 1.8rem !important;
	color: rgba(80, 60, 45, 0.9) !important;
  }
  
  /* Spacebar */
  body .hg-button[data-skbtn="{space}"] {
	min-width: 45% !important;
	border-style: dashed !important;
  }
  
  /* Text Input Field */
  .easca-input {
	position:fixed;
	top:0;
	left:0;
	width:100%;
	color: rgba(70, 50, 35, 0.9) !important;
	font-size: 2.2rem !important;
	letter-spacing: -0.5px;
	text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
	background: transparent !important;
	padding:2%;
  }
  
  /* Options Menu */
  .options-menu button {
	background: rgba(245, 235, 220, 0.95) !important;
	border: 2px solid rgba(94, 72, 52, 0.4) !important;
	border-radius: 8px !important;
	font-size: 1.8rem !important;
	padding: 0.5rem 1rem !important;
  }
  
  /* Mobile Optimization */
  @media (max-width: 768px) {
	body .hg-theme-default .hg-button {
	  font-size: 1.6rem !important;
	  height: 3.5rem !important;
	  min-width: 3rem !important;
	  border-radius: 10px !important;
	}
	
	.easca-input {
	  font-size: 1.8rem !important;
	}
  }

  #console-bg{
	display:none;
  }
  .bg-container{
	display: none;;
  }

  * {
	font-family: "aonchlo", sans-serif !important; /* Use !important to override defaults */
  }
  .options-menu{
	position:fixed;
	z-index: 90000;
	top:10%;
	left:10%;
	border:10px solid rgb(255, 156, 255);	
  }