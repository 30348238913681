.touch-prompt {
  pointer-events: none;
  z-index: 9999;
  width: 70%;
  height: 70px;
  border-radius: 50%;
  border: 1px solid #418c6724;
  animation: ripple 8s infinite ease-out;
  opacity: 0;
  animation-delay: 12s;

  
}

.touch-prompt-container {
  pointer-events: none; }

.title-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 10%;
  left: 0%;
  width: 100%;
  z-index: 510;
  pointer-events: none;
}

.title-text {
  height: 130px;
  width: 600px;
  background-image: url('../../images/iron-key2.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  pointer-events: none;
  animation: tiltAndTurn 24s infinite alternate-reverse ease-in-out;
}

@keyframes tiltAndTurn {
  0% { transform: rotateX(0deg) rotateY(0deg) scale(0.85);opacity: 0.1; }
  20% { transform: rotateX(-5deg) rotateY(5deg) scale(0.85); opacity: 0.1; }
  40% { transform: rotateX(5deg) rotateY(-5deg) scale(0.9);opacity: 0.2;  }
  60% { transform: rotateX(-3deg) rotateY(3deg) scale(1);opacity: 0.3;  }
  80% { transform: rotateX(3deg) rotateY(-3deg) scale(1);opacity: 0.3;  }
  100% { transform: rotateX(0deg) rotateY(0deg) scale(0.85);opacity: 0.1;  }
}
canvas{
  position: fixed;
  z-index: 2;
}
.start-button-container {
  position: fixed;
  top: 55%;  /* Button at bottom 1/3 of the screen */
  left: 0%;  /* Move to the center horizontally */
  width: 100% !important;
  display: grid;
  place-items: center; /* Centers content both horizontally and vertically */
  z-index: 1105 !important;
  align-items: center;
}
.start-fullscreen-button {
  width: 61px;
  height: 61px;
  background-image: url('../../images/middle-c.png');
  background-repeat: no-repeat;
  background-color: transparent;
  animation: spin 32s linear infinite, fadeIn 2s ease-in forwards;
  transition: opacity 0.5s ease;
  scale: 1.2;
  transform-origin: center; /* Ensures the spin happens around the center */
}



.start-fullscreen-button:active {
  transform: scale(0.95);
}

@keyframes spin {
  from { transform: rotate(360deg); }
  to { transform: rotate(0deg); }
}

.start-fullscreen-button.hidden {
  opacity: 0;
  pointer-events: none;
}
/* Fade In for title and button */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.title-container, .button-container {
  opacity: 0; /* Start with hidden */
  animation: fadeIn 2s forwards; /* Fade in over 2 seconds */
}

/* Mask Screen */
#mask {
  position: fixed;
  pointer-events: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 1; /* Full black screen initially */
  z-index: 999; /* Make sure it's on top */
  transition: opacity 1s ease-out; /* Fade out over 1 second */
}
html, body, #phaser-container, canvas {
  margin: 0;
  padding: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
}



.touch-prompt:nth-child(2) {
  animation-delay: 3.5s;
  border: 1px solid #36636540;
  width: 120px !important;
  height: 120px!important;
  
}

.touch-prompt:nth-child(3) {
  animation-delay: 15s;
  border: 1px solid #d784f037;
  width: 200px !important;
  height: 200px!important;
}


/* Force button above all Phaser elements */
.start-button-container {
  position: relative;
  z-index: 2000 !important; /* Max z-index value */
  pointer-events: auto !important;
}

/* Contain Phaser canvas z-index */
#phaser-container {
  position: fixed;
  z-index: 0 !important;
  pointer-events: none;
}

#phaser-container canvas {
  pointer-events: auto !important;
}


#portrait{

  display: none;
  
}

#phaser-container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  background: black; /* Prevent white edges when rotating */
}

@media screen and (orientation: portrait) {
  #phaser-container {
    transform: rotate(90deg);
    transform-origin: center center;
    width: 100vh;
    height: 100vw;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(90deg);
  }
}

@media screen and (orientation: portrait) {
  body {
    transform: rotate(90deg);
    transform-origin: center;
    width: 100vh;
    height: 100vw;
    overflow: hidden;
    position: fixed;
  }
}
