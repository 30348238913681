@font-face {
    font-family: 'INFO56_0';
    src: url('../../../../fonts/INFO56_0.ttf');
    font-weight: 400;
    font-weight: normal;
}

@font-face {
    font-family: 'aonchlo';
    src: url('../../../../fonts/aonchlo.ttf');
    font-weight: 400;
    font-weight: normal;
}