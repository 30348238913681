@import-normalize;
@font-face {
  font-family: 'IrishPenny';
  src: url(./fonts/IrishPenny.ttf);
      
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

/*
* Prefixed by:
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

@font-face {
  font-family: urchlo;
  src: url(./fonts/urchlo.ttf);
  font-display: swap; 
}
@font-face {
  font-display: swap; 
  font-family: anaphora;
  src: url(./fonts/Anaphora-Light-trial.ttf);
}
@font-face {
  font-family: aonchlo;
  font-display: swap; /* or fallback, optional, see explanation below */
  src: url(./fonts/aonchlo.ttf);
}



.disappear{
  -webkit-animation: fade-out 1s forwards;
          animation: fade-out 1s forwards;
  pointer-events: none;
}
body {

  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  min-height: 100vh;
  position: relative;
}

html,
body {
  margin: 0;
  overflow: hidden;
  overflow-x: hidden;
  overflow-y: hidden;

/* background-color:black; */
}

* {
  font-family: "Verdana", cursive, sans-serif;
  color: #ffffff;
}

.app {
  width: 450px;
  min-height: 200px;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  border-radius: 15px;
  padding: 20px;
  /* box-shadow: 10px 10px 42px 0px rgba(0, 0, 0, 0.75); */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;

  margin-left: 18%;
  margin-top: 10%;
}

@-webkit-keyframes delay-fade-in {
  0% {
     opacity: 0;
  }

  70% {
    opacity: 0;

  }
  100% {
    opacity: 1;

  }
  
}
@keyframes delay-fade-in {
  0% {
     opacity: 0;
  }

  70% {
    opacity: 0;

  }
  100% {
    opacity: 1;

  }
  
}
